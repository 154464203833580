import "./scss/style.scss";

window.addEventListener("load", () => {
  // header リンクエフェクト
  const headerNavLink = document.querySelectorAll('.js-headerNavLink');
  if(headerNavLink) {    
    headerNavLink.forEach((navLink) => {
      navLink.addEventListener('mouseenter', () => {
        gsap.to(navLink,{'--linkLine':1,duration:.25});
      })
      navLink.addEventListener('mouseleave', () => {
        gsap.to(navLink,{'--linkLine':0,duration:.35});
      })
    });
  }

  // スマホメニュー表示・非表示
  const menuButton = document.querySelector(".js-navBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavLinks = document.querySelectorAll(".js-headerNavLink");
  const headerBackground = document.querySelector(".js-headerBg");
  const bodyElement = document.querySelector("body");
  const toggleNav = () => {
    menuButton.classList.toggle("is-open");
    headerNav.classList.toggle("is-open");
    headerBackground.classList.toggle("is-open");
    bodyElement.classList.toggle("is-open");
  };
  const removeNav = () => {
    menuButton.classList.remove("is-open");
    headerNav.classList.remove("is-open");
    headerBackground.classList.remove("is-open");
    bodyElement.classList.remove("is-open");
  };
  menuButton.addEventListener("click", () => {
    if(menuButton.classList.contains('is-open')) {
      removeNav();
      gsap.fromTo('.header__navItem', {autoAlpha:1}, {autoAlpha:0});
    } else {
      toggleNav();
      gsap.fromTo('.header__navItem', {autoAlpha:0,y:10},{autoAlpha:1,y:0,delay:.5,stagger:.025}, '-=.1');
    }
  });
  headerNavLinks.forEach((navLink) => {
    navLink.addEventListener("click", removeNav);
  });
  headerBackground.addEventListener("click", removeNav );

  // headerメニューの表示・非表示をメディアクエリで管理
  const mediaQuery = window.matchMedia('(min-width: 768px)');
  handle(mediaQuery);
  mediaQuery.addEventListener('change', handle);
  function handle(mm) {
    const headerNavItems = document.querySelectorAll('.header__navItem');
    if(mm.matches){
      headerNavItems.forEach((headerNavItem) => {
        headerNavItem.style.opacity = '1';
        headerNavItem.style.visibility = 'visible'
      })
    } else {
      headerNavItems.forEach((headerNavItem) => {
        headerNavItem.style.opacity = '0';
        headerNavItem.style.visibility = 'hidden';
      })
    }
  }

  // header スクロール変化
  const header = document.querySelector('.js-header');
  const headerHeight = header.offsetHeight;

  function headerFixed() {
    const scroll = window.scrollY;
    if (scroll >= headerHeight) {
      header.classList.add('is-active');
    } else {
      header.classList.remove('is-active');
    }
  }

  window.addEventListener('scroll', function () {
    headerFixed();
  });

  // 追従ボタン footer直前で止まる
  const pageTop = document.querySelector('.js-pageTopBtn');
  const pageTopHeight = pageTop.clientHeight;
  
  pageTop.style.opacity = '0';
  
  window.addEventListener('scroll', function () {
    if (window.scrollY > 100) {
      pageTop.style.opacity = '1';
    } else {
      pageTop.style.opacity = '0';
    }
  });

  pageTop.addEventListener('click', function (e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });

  // フッター手前でストップ
  window.addEventListener('scroll', function () {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const footHeight = document.querySelector('footer').offsetHeight;
    
    if (scrollHeight - scrollPosition <= footHeight - pageTopHeight) {
      pageTop.style.position = 'absolute';
      pageTop.style.bottom = footHeight - pageTopHeight / 2 + 'px';
    } else {
      pageTop.style.position = 'fixed';
      pageTop.style.bottom = 20 + 'px';
    }
  });
});
